function Newsletter() {
  return (
    <main className="container">
      <div className="Newsletter-main">
        <h2>Anmeldung zum Newsletter</h2>
        <p>
          Vielen Dank, dass Du Dich bei unserem Newsletter angemeldet hast. So
          verpasst Du keine wichtigen Informationen rund um das Bike the line -
          Event.
        </p>
      </div>
    </main>
  );
}

export default Newsletter;
